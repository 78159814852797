export default {
  // Endpoints
  loginEndpoint: '/cubus/login',
  registerEndpoint: '/cubus/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/cubus/logout',
  whoamiEndpoint: '/cubus/whoami',
  changepassEndpoint: '/cubus/changepassword',
  queryEndpoint: '/cubus/qry',
  uploadEndpoint: '/cubus/upload',
  uploadchunkEndpoint: '/cubus/uploadchunk',
  downloadEndpoint: '/cubus/download',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
  storageCubusTokenKeyName: 'cubusToken',

  // headers
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
}
